<template>
	<div class="l-main max-w-layout-max mx-auto">
		<SkipToContent />

		<!--
			Remove if cookieinformation is not picked as
			the cookie banner solution.
		-->
		<CookieInformation :tag="false" />

		<!--
			Go to ~/assets/js/theme-configuration.js to set
			the default solution configuration (typically
			the configuration of the main solution).
		-->
		<ThemeConfiguration
			:config="{
				spacing: {
					menu: {
						sm: 110,
						md: 140,
						lg: 140,
					},
				},
				fontSize: {
					'h1-frontpage': {
						fontSize: {
							sm: 32,
							md: 30,
							lg: 30,
						},
						lineHeight: {
							sm: 1.15,
							md: 1.1,
							lg: 1.1,
						},
						letterSpacing: {
							sm: 0,
							md: 0,
							lg: 0,
						},
					},
				},
			}"
		/>
		<TransitionExt :duration="500" name="breaking-banner">
			<div
				v-if="
					$store.state.site &&
					$store.state.site.breakingNews &&
					$store.state.site.breakingNews.breakingActive &&
					!breakingClosed
				"
				class="w-full"
			>
				<BreakingBanner :content="$store.state.site.breakingNews" />
			</div>
		</TransitionExt>

		<div class="relative">
			<TransitionExt :duration="500" name="main__nav-header">
				<NavigationHeader
					v-if="showHeader"
					@drop-down="closeBreakingBanner"
				/>
			</TransitionExt>
		</div>

		<main id="main">
			<Nuxt />
		</main>

		<PageFooter v-if="showFooter" />

		<!-- Portal Targets -->
		<GlobalOverlay />
	</div>
</template>

<script>
import SkipToContent from '~/citi-baseline/components/SkipToContent';
import ThemeConfiguration from '~/citi-baseline/components/ThemeConfiguration';
import CookieInformation from '~/citi-baseline/components/CookieInformation';
import GlobalOverlay from '~/components/global/GlobalOverlay';
import NavigationHeader from '~/components/subsite/NavigationHeader';
import PageFooter from '~/components/subsite/PageFooter';
import BreakingBanner from '~/components/subsite/BreakingBanner';
import { defaultConfig } from '~/assets/js/theme-configuration.js';

const cloneDeep = require('clone-deep');

export default {
	name: 'SubsiteDefault',

	components: {
		SkipToContent,
		ThemeConfiguration,
		CookieInformation,
		GlobalOverlay,
		NavigationHeader,
		PageFooter,
		BreakingBanner,
	},

	data() {
		return {
			useTestSizes: true,
			breakingClosed: false,
		};
	},

	computed: {
		showHeader() {
			return this.$store.state.template !== 'SearchPageSubSite';
		},
		showFooter() {
			return this.$store.state.template !== 'SearchPageSubSite';
		},
		scaleTestConfig() {
			const config = cloneDeep(defaultConfig);
			const scaleTestConfig = {};

			// Scale spacings
			if (
				!isNaN(+this.$route.query.minSpacing) ||
				!isNaN(+this.$route.query.maxSpacing)
			) {
				let minSpacing = 1000;
				let maxSpacing = 0;

				scaleTestConfig.spacing = {};

				Object.keys(config.spacing).forEach((key) => {
					scaleTestConfig.spacing[key] = config.spacing[key];
					minSpacing = Math.min(minSpacing, config.spacing[key].sm);
					maxSpacing = Math.max(maxSpacing, config.spacing[key].lg);
				});

				const diff = maxSpacing - minSpacing;
				const newMin = isNaN(+this.$route.query.minSpacing)
					? minSpacing
					: +this.$route.query.minSpacing;
				const newMax = isNaN(+this.$route.query.maxSpacing)
					? maxSpacing
					: +this.$route.query.maxSpacing;
				const newDiff = newMax - newMin;

				Object.keys(scaleTestConfig.spacing).forEach((key) => {
					scaleTestConfig.spacing[key].sm = Math.round(
						((scaleTestConfig.spacing[key].sm - minSpacing) /
							diff) *
							newDiff +
							newMin
					);
					scaleTestConfig.spacing[key].md = Math.round(
						((scaleTestConfig.spacing[key].md - minSpacing) /
							diff) *
							newDiff +
							newMin
					);
					scaleTestConfig.spacing[key].lg = Math.round(
						((scaleTestConfig.spacing[key].lg - minSpacing) /
							diff) *
							newDiff +
							newMin
					);
				});
			}

			// Scale font sizes
			if (!isNaN(+this.$route.query.maxFontSize)) {
				let baseFontSize = 1000;
				let maxFontSize = 0;

				scaleTestConfig.fontSize = {};

				Object.keys(config.fontSize).forEach((key) => {
					scaleTestConfig.fontSize[key] = config.fontSize[key];
					maxFontSize = Math.max(
						maxFontSize,
						config.fontSize[key].fontSize.lg
					);
				});
				baseFontSize = Math.min(16, maxFontSize);

				const diff = maxFontSize - baseFontSize;
				const newMax = isNaN(+this.$route.query.maxFontSize)
					? maxFontSize
					: +this.$route.query.maxFontSize;
				const newDiff = newMax - baseFontSize;

				Object.keys(scaleTestConfig.fontSize).forEach((key) => {
					if (key !== 'body') {
						scaleTestConfig.fontSize[key].fontSize.sm = Math.round(
							((scaleTestConfig.fontSize[key].fontSize.sm -
								baseFontSize) /
								diff) *
								newDiff +
								baseFontSize
						);
						scaleTestConfig.fontSize[key].fontSize.md = Math.round(
							((scaleTestConfig.fontSize[key].fontSize.md -
								baseFontSize) /
								diff) *
								newDiff +
								baseFontSize
						);
						scaleTestConfig.fontSize[key].fontSize.lg = Math.round(
							((scaleTestConfig.fontSize[key].fontSize.lg -
								baseFontSize) /
								diff) *
								newDiff +
								baseFontSize
						);
					}
				});
			}

			return scaleTestConfig;
		},
	},

	mounted() {
		document.body.focus();
		window.addEventListener('keydown', (e) => {
			if (e.key === 'Shift') {
				this.useTestSizes = !this.useTestSizes;
			}
		});
	},

	methods: {
		closeBreakingBanner(val) {
			this.breakingClosed = val;
		},
	},
};
</script>

<style lang="postcss">
/*! purgecss start ignore */
// customer styles go here
@screen print {
	#Coi-Renew {
		visibility: hidden !important;
	}
}
/*! purgecss end ignore */
.l-main :where(#main, #main > *) {
	min-height: 100vh;
	min-height: calc(
		100vh - var(--theme-spacing-menu, var(--theme-spacing-menu--sm))
	);
}

.t-main__nav-header-enter-active,
.t-main__nav-header-leave-active {
	transition: opacity;
}
.t-main__nav-header-enter-active {
	transition-delay: 500ms;
}
.t-main__nav-header-enter,
.t-main__nav-header-leave-to {
	opacity: 0;
}
</style>
