var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Transition',{attrs:{"name":"t-burger-menu"},on:{"enter":_vm.onEnter,"after-leave":_vm.onAfterLeave}},[(_vm.isOpen)?_c('div',{directives:[{name:"trap-focus",rawName:"v-trap-focus.loop",value:({
			active: _vm.isOpen,
			additionalElementsBefore:
				'.c-navigation-header__persistent-buttons a, .c-navigation-header__persistent-buttons button',
		}),expression:"{\n\t\t\tactive: isOpen,\n\t\t\tadditionalElementsBefore:\n\t\t\t\t'.c-navigation-header__persistent-buttons a, .c-navigation-header__persistent-buttons button',\n\t\t}",modifiers:{"loop":true}}],staticClass:"c-burger-menu"},[_c('nav',{staticClass:"\n\t\t\t\tw-full\n\t\t\t\tpx-layout-margin\n\t\t\t\tpt-md\n\t\t\t\tpb-xl\n\t\t\t\tflex flex-col\n\t\t\t\tjustify-center\n\t\t\t\titems-center\n\t\t\t"},[(_vm.capButtons && _vm.capButtons.length)?_c('header',{staticClass:"\n\t\t\t\t\tabsolute\n\t\t\t\t\tinline-flex\n\t\t\t\t\tjustify-start\n\t\t\t\t\titems-center\n\t\t\t\t\tleft-layout-margin\n\t\t\t\t\ttop-0\n\t\t\t\t\th-menu\n\t\t\t\t\t>=1024:hidden\n\t\t\t\t"}):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"w-full"},_vm._l((_vm.items),function(item){return _c('li',{key:item.id,class:[
						'duration-200 ease-smooth-out',
						'text-white hover:text-primary-light',
						'text-left block',
						'border-b border-white border-opacity-10' ]},[_c('NuxtLinkExt',{staticClass:"text-burger",attrs:{"to":item.url}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t")])],1)}),0),_vm._v(" "),(_vm.capButtons && _vm.capButtons.length)?_c('footer',{staticClass:"\n\t\t\t\t\tmt-xl\n\t\t\t\t\tw-full\n\t\t\t\t\tflex\n\t\t\t\t\tjustify-center\n\t\t\t\t\titems-center\n\t\t\t\t\tgap-x-10\n\t\t\t\t"},_vm._l((_vm.capButtons),function(item){return _c('BaseButton',{key:item.id,attrs:{"tag":"NuxtLinkExt","to":item.url,"target":item.target,"type":"primary-light"}},[_vm._v("\n\t\t\t\t\t"+_vm._s(item.name)+"\n\t\t\t\t")])}),1):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }