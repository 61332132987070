var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-main max-w-layout-max mx-auto"},[_c('SkipToContent'),_vm._v(" "),_c('CookieInformation',{attrs:{"tag":false}}),_vm._v(" "),_c('ThemeConfiguration',{attrs:{"config":{
			spacing: {
				menu: {
					sm: 110,
					md: 140,
					lg: 140,
				},
			},
			fontSize: {
				'h1-frontpage': {
					fontSize: {
						sm: 32,
						md: 30,
						lg: 30,
					},
					lineHeight: {
						sm: 1.15,
						md: 1.1,
						lg: 1.1,
					},
					letterSpacing: {
						sm: 0,
						md: 0,
						lg: 0,
					},
				},
			},
		}}}),_vm._v(" "),_c('TransitionExt',{attrs:{"duration":500,"name":"breaking-banner"}},[(
				_vm.$store.state.site &&
				_vm.$store.state.site.breakingNews &&
				_vm.$store.state.site.breakingNews.breakingActive &&
				!_vm.breakingClosed
			)?_c('div',{staticClass:"w-full"},[_c('BreakingBanner',{attrs:{"content":_vm.$store.state.site.breakingNews}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"relative"},[_c('TransitionExt',{attrs:{"duration":500,"name":"main__nav-header"}},[(_vm.showHeader)?_c('NavigationHeader',{on:{"drop-down":_vm.closeBreakingBanner}}):_vm._e()],1)],1),_vm._v(" "),_c('main',{attrs:{"id":"main"}},[_c('Nuxt')],1),_vm._v(" "),(_vm.showFooter)?_c('PageFooter'):_vm._e(),_vm._v(" "),_c('GlobalOverlay')],1)}
var staticRenderFns = []

export { render, staticRenderFns }