<template>
	<footer v-if="footerExists" id="footer" class="c-page-footer print:hidden">
		<section
			v-if="address"
			:class="[
				'bg-white rounded-16 p-md',
				'col-start-1 col-span-6 <768:col-start-1 <768:col-span-12',
			]"
		>
			<BaseH3
				v-if="address.title"
				tag="h2"
				class="mb-sm"
				v-text="address.title"
			/>

			<div class="space-y-xs">
				<a
					v-if="address.street || address.postalCode || address.city"
					:href="computedAddressMapsUrl"
					target="_blank"
					class="
						flex
						space-x-xs
						items-start
						cursor-pointer
						hover:underline
					"
				>
					<SvgLocation class="w-xs mt-4" />

					<span>
						{{ address.street }}<br />
						{{ address.postalCode }}
						{{ address.city }}
					</span>
				</a>

				<a
					v-if="address.phone"
					class="flex space-x-xs items-start hover:underline"
					:href="`tel:${address.phone}`"
				>
					<SvgPhone class="w-xs mt-4" />
					<span v-text="address.phone"></span>
				</a>

				<a
					v-if="address.email"
					class="flex space-x-xs items-start hover:underline"
					:href="`mailto:${address.email}`"
				>
					<SvgMail class="w-xs mt-4" />
					<span v-text="address.email"></span>
				</a>

				<div v-if="address.cvr || address.ean">
					<div v-if="address.cvr" class="flex space-x-xs items-start">
						<SvgPaper class="w-xs mt-4" />

						<span> CVR/SE: {{ address.cvr }} </span>
					</div>
					<div
						v-if="address.ean"
						class="flex space-x-xs items-start"
						:class="{
							'mt-8': address.cvr,
						}"
					>
						<SvgPaper class="w-xs mt-4" />

						<span> EAN: {{ address.ean }} </span>
					</div>
				</div>
			</div>

			<div
				v-if="computedSoMe && computedSoMe.length"
				class="flex flex-wrap gap-x-12 gap-y-12 mt-md"
			>
				<div
					v-for="(link, index) in computedSoMe"
					:key="`some-${index}`"
					:class="link.url ? '' : 'hidden'"
				>
					<NuxtLinkExt
						v-if="link.url"
						:to="link.url"
						target="_blank"
						:class="[
							'block text-primary-button hover:text-primary-button-hover',
							'duration-500 ease-smooth-out',
						]"
						:aria-label="link.label"
					>
						<Component :is="link.icon" class="w-32" />
					</NuxtLinkExt>
				</div>
			</div>
		</section>

		<section
			:class="[
				'bg-white rounded-16 p-md',
				'col-start-7 col-span-6 <768:col-start-1 <768:col-span-12',
				'grid grid-cols-6 items-stretch',
			]"
		>
			<div
				v-if="seeAlso"
				class="
					flex flex-col
					items-stretch
					col-start-1 col-span-4
					<768:col-span-full
				"
			>
				<BaseH3
					v-if="seeAlso.title"
					tag="h2"
					class="mb-sm"
					v-text="seeAlso.title"
				/>

				<div v-if="seeAlso.links && seeAlso.links.length">
					<div
						:class="[
							'grid grid-cols-1 <768:col-span-full',
							'gap-y-xs gap-x-sm max-w-864',
						]"
					>
						<ContentLink
							v-for="(link, index) in seeAlso.links"
							:key="`seealso-${index}`"
							:to="link.url"
							:target="link.target"
						>
							{{ link.name }}
						</ContentLink>
					</div>
				</div>

				<div v-if="seeAlso.readOutLoud" class="mt-auto">
					<NuxtLinkExt
						class="mt-md inline-block"
						:to="seeAlso.readOutLoud.url"
						:target="seeAlso.readOutLoud.target"
					>
						<BaseButton tag="div" type="secondary">
							<template #icon>
								<SvgReadLoud class="w-16 h-16" />
							</template>
							<template #default>Læs Højt</template>
						</BaseButton>
					</NuxtLinkExt>
				</div>
			</div>

			<div class="col-start-5 col-span-2 flex justify-center items-end">
				<NuxtLinkExt
					aria-label="Gå til Slagelse Kommunes hjemmeside"
					to="https://www.slagelse.dk/da/"
					target="_blank"
				>
					<SvgFullLogo class="w-112" />
				</NuxtLinkExt>
			</div>
		</section>

		<section
			v-if="showPartners && partners && partners.items"
			:class="['bg-white rounded-16 p-md', 'col-start-1 col-span-12']"
		>
			<BaseH3
				v-if="partners.title"
				tag="h2"
				class="mb-sm"
				v-text="partners.title"
			/>

			<ul
				class="
					w-full
					flex flex-wrap
					justify-center
					items-center
					gap-layout-gutter
				"
			>
				<li
					v-for="{ content } in partners.items"
					:key="content.key"
					class="
						w-partnerWidth
						flex flex-col
						justify-center
						items-center
						text-center
						flex-shrink-0
					"
				>
					<NuxtLinkExt
						:to="
							(content.partnerLink &&
								content.partnerLink[0] &&
								content.partnerLink[0].url) ||
							'#'
						"
						:target="
							(content.partnerLink &&
								content.partnerLink[0] &&
								content.partnerLink[0].target) ||
							'_blank'
						"
						class="
							pointer-events-none
							inline-flex
							flex-col
							justify-center
							items-center
							gap-xs
						"
					>
						<Component
							:is="content.partnerlogo ? 'figure' : 'div'"
							class="flex justify-center items-center"
							:style="{
								pointerEvents: content.partnerlogo
									? 'auto'
									: 'none',
								height: `calc(${
									maxPartnerHeight / 82
								} * var(--theme-spacing-partnerWidth, var(--theme-spacing-partnerWidth--sm)) * 0.4)`,
								aspectRatio: `94 / ${maxPartnerHeight}`,
							}"
						>
							<UmbracoImageExt
								v-if="
									content.partnerlogo &&
									content.partnerlogo.cropUrl
								"
								:alt="content.partnerlogo.altText"
								:widths="[
									(getPartnerImgSize(
										content.partnerlogo.width,
										content.partnerlogo.height
									).width /
										206) *
										300,
								]"
								class="w-auto h-full"
								:source-url="
									content.partnerlogo.cropUrl ||
									content.partnerlogo.url
								"
								:source-width="content.partnerlogo.width"
								:source-height="content.partnerlogo.height"
								image-class-names="object-contain"
							/>
						</Component>
						<h3
							class="text-h5 font-bold pointer-events-auto"
							v-text="content.partnerName"
						></h3>
					</NuxtLinkExt>
				</li>
			</ul>
		</section>
	</footer>
</template>

<script>
import NuxtLinkExt from '~/citi-baseline/components/NuxtLinkExt.vue';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt.vue';
import ContentLink from '~/components/shared/ContentLink';
import SvgLocation from '~/assets/svgs/location-icon.svg?inline';
import SvgPhone from '~/assets/svgs/phone-icon.svg?inline';
import SvgMail from '~/assets/svgs/mail-icon.svg?inline';
import SvgPaper from '~/assets/svgs/paper-icon.svg?inline';
import SvgFacebook from '~/assets/svgs/facebook-icon.svg?inline';
import SvgTwitter from '~/assets/svgs/twitter-x-icon.svg?inline';
import SvgInstagram from '~/assets/svgs/instagram-icon.svg?inline';
import SvgYoutube from '~/assets/svgs/youtube-icon.svg?inline';
import SvgLinkedIn from '~/assets/svgs/linkedin-icon.svg?inline';
import SvgReadLoud from '~/assets/svgs/read-loud-icon.svg?inline';
import SvgFullLogo from '~/assets/svgs/full-logo.svg?inline';

export default {
	name: 'PageFooter',

	components: {
		NuxtLinkExt,
		UmbracoImageExt,
		ContentLink,
		SvgLocation,
		SvgPhone,
		SvgMail,
		SvgPaper,
		SvgFacebook,
		SvgTwitter,
		SvgInstagram,
		SvgYoutube,
		SvgLinkedIn,
		SvgReadLoud,
		SvgFullLogo,
	},

	data() {
		const data = this.$store.state.site?.footer;
		const soMeItems = this.$store.state.site?.soMeItems;
		const authenticatorRequest =
			this.$store.state.site?.authenticatorRequest || {};

		return {
			footerExists: !!data,
			...data,
			soMeItems,
			authenticatorRequest,
			isLoggedIn: null,
			pageId: null,
		};
	},

	computed: {
		computedContact() {
			const { openingHours, book } = this.contact;
			const response = [];

			openingHours && response.push(openingHours);
			book && response.push(book);
			return response;
		},
		computedAddressMapsUrl() {
			const addressParts = [
				this.address.street,
				this.address.postalCode,
				this.address.city,
			];
			const filteredAddress = addressParts.filter(Boolean).join(' ');
			const encodedAddress = encodeURIComponent(filteredAddress);
			return `https://www.google.com/maps?q=${encodedAddress}`;
		},

		computedSoMe() {
			const someMap = {
				facebookUrl: {
					icon: 'SvgFacebook',
					label: 'Gå til Facebook-side',
				},
				instagramUrl: {
					icon: 'SvgInstagram',
					label: 'Gå til Instagram-side',
				},
				linkedInUrl: {
					icon: 'SvgLinkedIn',
					label: 'Gå til LinkedIn-side',
				},
				twitterUrl: {
					icon: 'SvgTwitter',
					label: 'Gå til Twitter-side',
				},
				youTubeUrl: {
					icon: 'SvgYoutube',
					label: 'Gå til YouTube-side',
				},
			};

			return Object.keys(this.soMeItems).map((key) => ({
				url: this.soMeItems[key],
				...someMap[key],
			}));
		},

		maxPartnerHeight() {
			const logos = (
				this.partners.items?.map?.(
					(item) => item?.content?.partnerlogo
				) || []
			).filter(Boolean);
			const maxHeight = Math.min(
				82,
				Math.max(
					0,
					...logos.map(
						(logo) =>
							this.getPartnerImgSize(logo.width, logo.height)
								.height
					)
				)
			);
			return maxHeight || 0;
		},
	},

	watch: {
		'$route.path': {
			handler() {
				this.pageId = this.$store.state?.pageId;
				// this.getEditorLink();
			},
		},
	},

	mounted() {
		this.pageId = this.$store.state?.pageId;
		// this.getEditorLink();
	},

	methods: {
		getPartnerImgSize(originalWidth, originalHeight) {
			const ratio = originalWidth / originalHeight;

			const originalArea = originalWidth * originalHeight;
			const targetArea = 82 * 82;

			const areaRatio = originalArea / targetArea;
			const areaContribution = Math.sqrt(areaRatio);

			let width = Math.min(
				Math.round(originalWidth / areaContribution),
				94
			);
			const height =
				Math.round(Math.min(width / ratio, 82) * 1000) / 1000;
			width = Math.round(height * ratio * 1000) / 1000;

			return {
				width,
				height,
			};
		},
		getEditorLink() {
			this.$axios
				.get(this.authenticatorRequest.url, { withCredentials: true })
				.then((response) => {
					response && (this.isLoggedIn = response);
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
};
</script>

<style lang="postcss">
.c-page-footer {
	@apply transition duration-300 ease-in-out;
	@apply grid grid-cols-12 gap-layout-gutter;
	@apply bg-primary-button p-layout-margin;
}
</style>
