<template>
	<div
		v-if="content && content.breakingActive && !closed"
		class="
			c-breaking-banner
			bg-extra
			mobile:mx-0
			relative
			text-white
			font-medium
			py-md
			px-md
			<1024:pt-xl
			print:hidden
		"
	>
		<div class="w-6col <1024:w-full">
			<BaseButton
				class="c-breaking-banner__close absolute top-sm right-sm"
				outline="white-2"
				@click="closeBreakingNews"
				v-text="'Luk'"
			>
				<template #icon><SvgCross /></template>
				<template #default>Luk</template>
			</BaseButton>
			<div class="flex items-center">
				<SvgAlertIcon class="w-16 h-16 mr-xs" />
				<h2 class="text-h2 font-semibold">
					{{ content.breakingTitle }}
				</h2>
			</div>
			<p class="mt-xs" v-html="content.breakingDescription"></p>
			<NuxtLinkExt
				v-if="content.breakingLink && content.breakingLink.url"
				class="w-fit block"
				:to="content.breakingLink.url"
				:target="content.breakingLink.target"
			>
				<BaseButton
					tag="div"
					type="white"
					class="w-fit mt-md"
					v-text="content.breakingLink.name"
				>
					<template #icon>
						<SvgExternalArrowIcon
							v-if="content.breakingLink.target === '_blank'"
							class="w-14 h-12"
						/>
						<SvgArrowIcon v-else class="w-14 h-12" />
					</template>
				</BaseButton>
			</NuxtLinkExt>
		</div>
	</div>
</template>

<script>
import SvgAlertIcon from '~/assets/svgs/alert-icon.svg?inline';
import SvgArrowIcon from '~/assets/svgs/arrow-icon.svg?inline';
import SvgExternalArrowIcon from '~/assets/svgs/external-arrow-icon.svg?inline';
import SvgCross from '~/assets/svgs/cross-icon.svg?inline';

export default {
	name: 'BreakingBanner',
	components: { SvgAlertIcon, SvgArrowIcon, SvgExternalArrowIcon, SvgCross },
	props: {
		content: Object,
	},

	data() {
		return {
			closed: true,
		};
	},
	mounted() {
		if (
			localStorage &&
			localStorage.breakingNewsClose &&
			this.content != null &&
			// eslint-disable-next-line eqeqeq
			localStorage.breakingNewsClose == this.content.breakingHash
		) {
			this.closed = true;
		} else {
			this.closed = null;
		}
	},
	methods: {
		closeBreakingNews() {
			const { breakingHash } = this.content;
			localStorage.breakingNewsClose = breakingHash;
			this.closed = true;
		},
	},
};
</script>

<style lang="postcss">
.c-breaking-banner__close:before {
	@apply bg-transparent !important;
}
</style>
