var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.footerExists)?_c('footer',{staticClass:"c-page-footer print:hidden",attrs:{"id":"footer"}},[(_vm.address)?_c('section',{class:[
			'bg-white rounded-16 p-md',
			'col-start-1 col-span-6 <768:col-start-1 <768:col-span-12' ]},[(_vm.address.title)?_c('BaseH3',{staticClass:"mb-sm",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.address.title)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"space-y-xs"},[(_vm.address.street || _vm.address.postalCode || _vm.address.city)?_c('a',{staticClass:"\n\t\t\t\t\tflex\n\t\t\t\t\tspace-x-xs\n\t\t\t\t\titems-start\n\t\t\t\t\tcursor-pointer\n\t\t\t\t\thover:underline\n\t\t\t\t",attrs:{"href":_vm.computedAddressMapsUrl,"target":"_blank"}},[_c('SvgLocation',{staticClass:"w-xs mt-4"}),_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.address.street)),_c('br'),_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.address.postalCode)+"\n\t\t\t\t\t"+_vm._s(_vm.address.city)+"\n\t\t\t\t")])],1):_vm._e(),_vm._v(" "),(_vm.address.phone)?_c('a',{staticClass:"flex space-x-xs items-start hover:underline",attrs:{"href":("tel:" + (_vm.address.phone))}},[_c('SvgPhone',{staticClass:"w-xs mt-4"}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.address.phone)}})],1):_vm._e(),_vm._v(" "),(_vm.address.email)?_c('a',{staticClass:"flex space-x-xs items-start hover:underline",attrs:{"href":("mailto:" + (_vm.address.email))}},[_c('SvgMail',{staticClass:"w-xs mt-4"}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.address.email)}})],1):_vm._e(),_vm._v(" "),(_vm.address.cvr || _vm.address.ean)?_c('div',[(_vm.address.cvr)?_c('div',{staticClass:"flex space-x-xs items-start"},[_c('SvgPaper',{staticClass:"w-xs mt-4"}),_vm._v(" "),_c('span',[_vm._v(" CVR/SE: "+_vm._s(_vm.address.cvr)+" ")])],1):_vm._e(),_vm._v(" "),(_vm.address.ean)?_c('div',{staticClass:"flex space-x-xs items-start",class:{
						'mt-8': _vm.address.cvr,
					}},[_c('SvgPaper',{staticClass:"w-xs mt-4"}),_vm._v(" "),_c('span',[_vm._v(" EAN: "+_vm._s(_vm.address.ean)+" ")])],1):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.computedSoMe && _vm.computedSoMe.length)?_c('div',{staticClass:"flex flex-wrap gap-x-12 gap-y-12 mt-md"},_vm._l((_vm.computedSoMe),function(link,index){return _c('div',{key:("some-" + index),class:link.url ? '' : 'hidden'},[(link.url)?_c('NuxtLinkExt',{class:[
						'block text-primary-button hover:text-primary-button-hover',
						'duration-500 ease-smooth-out' ],attrs:{"to":link.url,"target":"_blank","aria-label":link.label}},[_c(link.icon,{tag:"Component",staticClass:"w-32"})],1):_vm._e()],1)}),0):_vm._e()],1):_vm._e(),_vm._v(" "),_c('section',{class:[
			'bg-white rounded-16 p-md',
			'col-start-7 col-span-6 <768:col-start-1 <768:col-span-12',
			'grid grid-cols-6 items-stretch' ]},[(_vm.seeAlso)?_c('div',{staticClass:"\n\t\t\t\tflex flex-col\n\t\t\t\titems-stretch\n\t\t\t\tcol-start-1 col-span-4\n\t\t\t\t<768:col-span-full\n\t\t\t"},[(_vm.seeAlso.title)?_c('BaseH3',{staticClass:"mb-sm",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.seeAlso.title)}}):_vm._e(),_vm._v(" "),(_vm.seeAlso.links && _vm.seeAlso.links.length)?_c('div',[_c('div',{class:[
						'grid grid-cols-1 <768:col-span-full',
						'gap-y-xs gap-x-sm max-w-864' ]},_vm._l((_vm.seeAlso.links),function(link,index){return _c('ContentLink',{key:("seealso-" + index),attrs:{"to":link.url,"target":link.target}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(link.name)+"\n\t\t\t\t\t")])}),1)]):_vm._e(),_vm._v(" "),(_vm.seeAlso.readOutLoud)?_c('div',{staticClass:"mt-auto"},[_c('NuxtLinkExt',{staticClass:"mt-md inline-block",attrs:{"to":_vm.seeAlso.readOutLoud.url,"target":_vm.seeAlso.readOutLoud.target}},[_c('BaseButton',{attrs:{"tag":"div","type":"secondary"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgReadLoud',{staticClass:"w-16 h-16"})]},proxy:true},{key:"default",fn:function(){return [_vm._v("Læs Højt")]},proxy:true}],null,false,1816614800)})],1)],1):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-start-5 col-span-2 flex justify-center items-end"},[_c('NuxtLinkExt',{attrs:{"aria-label":"Gå til Slagelse Kommunes hjemmeside","to":"https://www.slagelse.dk/da/","target":"_blank"}},[_c('SvgFullLogo',{staticClass:"w-112"})],1)],1)]),_vm._v(" "),(_vm.showPartners && _vm.partners && _vm.partners.items)?_c('section',{class:['bg-white rounded-16 p-md', 'col-start-1 col-span-12']},[(_vm.partners.title)?_c('BaseH3',{staticClass:"mb-sm",attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.partners.title)}}):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"\n\t\t\t\tw-full\n\t\t\t\tflex flex-wrap\n\t\t\t\tjustify-center\n\t\t\t\titems-center\n\t\t\t\tgap-layout-gutter\n\t\t\t"},_vm._l((_vm.partners.items),function(ref){
					var content = ref.content;
return _c('li',{key:content.key,staticClass:"\n\t\t\t\t\tw-partnerWidth\n\t\t\t\t\tflex flex-col\n\t\t\t\t\tjustify-center\n\t\t\t\t\titems-center\n\t\t\t\t\ttext-center\n\t\t\t\t\tflex-shrink-0\n\t\t\t\t"},[_c('NuxtLinkExt',{staticClass:"\n\t\t\t\t\t\tpointer-events-none\n\t\t\t\t\t\tinline-flex\n\t\t\t\t\t\tflex-col\n\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\titems-center\n\t\t\t\t\t\tgap-xs\n\t\t\t\t\t",attrs:{"to":(content.partnerLink &&
							content.partnerLink[0] &&
							content.partnerLink[0].url) ||
						'#',"target":(content.partnerLink &&
							content.partnerLink[0] &&
							content.partnerLink[0].target) ||
						'_blank'}},[_c(content.partnerlogo ? 'figure' : 'div',{tag:"Component",staticClass:"flex justify-center items-center",style:({
							pointerEvents: content.partnerlogo
								? 'auto'
								: 'none',
							height: ("calc(" + (_vm.maxPartnerHeight / 82) + " * var(--theme-spacing-partnerWidth, var(--theme-spacing-partnerWidth--sm)) * 0.4)"),
							aspectRatio: ("94 / " + _vm.maxPartnerHeight),
						})},[(
								content.partnerlogo &&
								content.partnerlogo.cropUrl
							)?_c('UmbracoImageExt',{staticClass:"w-auto h-full",attrs:{"alt":content.partnerlogo.altText,"widths":[
								(_vm.getPartnerImgSize(
									content.partnerlogo.width,
									content.partnerlogo.height
								).width /
									206) *
									300 ],"source-url":content.partnerlogo.cropUrl ||
								content.partnerlogo.url,"source-width":content.partnerlogo.width,"source-height":content.partnerlogo.height,"image-class-names":"object-contain"}}):_vm._e()],1),_vm._v(" "),_c('h3',{staticClass:"text-h5 font-bold pointer-events-auto",domProps:{"textContent":_vm._s(content.partnerName)}})],1)],1)}),0)],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }